import { graphql } from 'gatsby'
import React, { useMemo } from 'react'
import CTA from '../components/ctaSmall'
import ImageGallery from '../components/imageGallery'
import IntroText from '../components/introText'
import Layout from '../components/layout'
import MoreProjects from '../components/moreProjects'
import ParallaxBanner from '../components/parallaxBanner'
import ProjectDetails from '../components/projectDetails'
import SEO from '../components/seo'
import Spacer from '../components/spacer'
import ConditionallyRender from '../components/utils/conditionally-render'

export default function Project({ data }) {
	var project = data?.datoCmsProject
	const projects = data?.projects
	const page = data?.allDatoCmsProjectsPage?.nodes[0]
	const projectDetails = useMemo(
		() => ({
			image: project?.projectDetailsImage,
			location: project?.location,
			comingSoon: project?.comingSoonText,
			builder: project?.builder,
			builderLink: project?.builderLink,
			architectInteriorDesign: project?.architectInteriorDesign,
			architectLink: project?.architectLink,
			developer: project?.developer,
			developerLink: project?.developerLink,
			projectDetailsImageTwo: project?.projectDetailsImageTwo,
			quote: {
				content: project?.projectQuote,
				name: project?.quoteName,
			},
			stats: project?.stats,
			link: project?.projectLink,
			linkText: project?.projectLinkText,
		}),
		[project]
	)

	function Info({ text }) {
		return (
			<div className='p40 max-1600 ma'>
				<div className='bt1'>
					<p className='black mt40 m0 h3'>{text}</p>
				</div>
			</div>
		)
	}

	return (
		<Layout footer='is-light'>
			<SEO title={project?.name} />
			<ParallaxBanner image={project?.heroImage} text={project?.heroCaption} caption={project?.heroImageCaption} />
			<div className='bg-white bg--light black'>
				<Spacer className='m-h-80' />
				<IntroText text={project?.introductionText} />
				<ProjectDetails projectDetails={projectDetails} />
				<Spacer className='m-h-60 ' />

				<ConditionallyRender when={project?.projectInfo}>
					<Info text={project?.projectInfo} />
					<Spacer className='m-h-60' />
				</ConditionallyRender>

				<ConditionallyRender when={project?.gallery?.length > 0}>
					<ImageGallery images={project?.gallery} text={project?.galleryCaption} />
					<Spacer className='m-h-60' />
				</ConditionallyRender>

				<MoreProjects projects={projects?.nodes} />
				<Spacer className='m-hide' />
				<CTA heading={page?.heading} text={page?.linkedText} link={page?.linkedPage} />
			</div>
		</Layout>
	)
}

export const query = graphql`
	query Project($name: String) {
		allDatoCmsProjectsPage {
			nodes {
				id
				linkedPage
				linkedText
				heading
			}
		}
		datoCmsProject(name: { eq: $name }) {
			name
			id
			introductionText
			heroCaption
			heroImageCaption
			heroImage {
				gatsbyImageData
			}
			builder
			builderLink
			projectInfo
			galleryCaption
			developer
			developerLink
			architectInteriorDesign
			architectLink
			location
			quoteName
			projectLink
			projectQuote
			projectLinkText
			comingSoonText
			stats {
				name
				value
			}
			gallery {
				url
			}
			projectDetailsImage {
				gatsbyImageData
			}
			projectDetailsImageTwo {
				gatsbyImageData
			}
		}
		projects: allDatoCmsProject(filter: { name: { ne: $name } }, limit: 4, sort: { fields: position }) {
			nodes {
				name
				id
				heroCaption
				slug
				year
				id
				heroImage {
					gatsbyImageData
				}
			}
		}
	}
`
